import React from 'react'
import PropTypes from 'prop-types'

import * as S from './nutrition-facts-styled'

const NutritionFacts = ({ servingSize, calories, macros }) => (
  <S.List>

    <S.TopListItem>
      <S.Label>Serving Size:</S.Label>
      <S.Value>{servingSize}, {calories} cal</S.Value>
    </S.TopListItem>

    {macros.map((macro, i) => (
      <S.ListItem key={i}>
        <S.Label>{macro.name}</S.Label>
        <S.Spacer />
        <S.Value>{macro.value}</S.Value>
      </S.ListItem>
    ))}

  </S.List>
)

NutritionFacts.propTypes = {
  servingSize: PropTypes.string.isRequired,
  calories: PropTypes.number.isRequired,
  macros: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
}

export { NutritionFacts }