import React from 'react'
import PropTypes from 'prop-types'

import prepIcon from './assets/prep_icon.svg'
import cookIcon from './assets/cook_icon.svg'
import servingsIcon from './assets/servings_icon.svg'
import * as S from './metrics-styled'

const Metrics = ({ prepTime, cookTime, servings }) => (
  <S.Container>

    <S.Metric>
      <S.IconArea>
        <S.IconImg
          src={prepIcon}
          alt="Prep Time Icon"
        />
      </S.IconArea>

      <S.LabelArea>Prep</S.LabelArea>

      <S.ValueArea>{prepTime}</S.ValueArea>
    </S.Metric>

    <S.Metric>
      <S.IconArea>
        <S.IconImg
          src={cookIcon}
          alt="Cook Time Icon"
        />
      </S.IconArea>

      <S.LabelArea>Cook</S.LabelArea>

      <S.ValueArea>{cookTime}</S.ValueArea>
    </S.Metric>

    <S.Metric>
      <S.IconArea>
        <S.IconImg
          src={servingsIcon}
          alt="Servings Icon"
        />
      </S.IconArea>

      <S.LabelArea>Servings</S.LabelArea>

      <S.ValueArea>{servings}</S.ValueArea>
    </S.Metric>

  </S.Container>
)

Metrics.propTypes = {
  prepTime: PropTypes.string.isRequired,
  cookTime: PropTypes.string.isRequired,
  servings: PropTypes.string.isRequired,
}

export { Metrics }