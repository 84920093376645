import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import { Link } from '../../../../common/text-styled'
import { media } from '../../../../../constants/media'

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ListItem = styled.li`
  width: 100%;
  padding: 15px 15px 15px 0;
  :not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.nori};
  }
`;

export const Item = styled.div`
  font-family: ${props => props.theme.fonts.poppins};
  color: ${props => props.theme.colors.nori};
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.3rem;
`;

export const Qty = styled.div`
  font-family: ${props => props.theme.fonts.poppins};
  color: ${props => props.theme.colors.salmon};
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.6rem;
  letter-spacing: 0.01rem;
  @media ${media.medium.query} {
    font-size: 1.6rem;
    line-height: 1.7rem;
    letter-spacing: 0.011rem;
  }
`;

export const A = styled(Link)`
`;

export const NewWindowIcon = styled(FontAwesomeIcon).attrs({
  icon: faExternalLinkAlt
})`
`;