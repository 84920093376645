import styled from 'styled-components'

import { media } from '../../../../constants/media'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  @media ${media.medium.query} {
    margin-bottom: 100px;
  }
`;

export const Iframe = styled.iframe`
  border-radius: 4px;
`;