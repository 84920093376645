import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/common/layout/layout-component'
import SEO from '../components/common/seo'
import { RecipeComponent } from '../components/pages/recipe/recipe-component'
import { EmailSubscribeForm } from '../components/common/email-subscribe-form/email-subscribe-form-container'

const RecipeTemplate = ({ data }) => (
  <Layout>

    <SEO
      title={`${data.strapiRecipe.title} Recipe`}
      keyword={['recipes', 'recipe', 'cooking', 'travel', 'travelling', 'authentic', 'original']}
    />

    <RecipeComponent
      recipe={data.strapiRecipe}
    />

    <EmailSubscribeForm showLabel />

  </Layout>
)

export default RecipeTemplate

export const query = graphql`
  query RecipeTemplate($id: String!) {
    strapiRecipe(id: {eq: $id}) {
      id
      title
      date
      countries {
        Name
        ShortName
      }
      prepTime
      cookTime
      yieldServings
      image {
        childImageSharp {
          fluid(maxWidth: 800, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      video
      description
      tags {
        Name
        id
      }
      ingredients {
        item
        qty
        url
        slugUrlIndex
      }
      directions
      notes
      nutritionFacts {
        servingSize
        calories
        macros {
          name
          value
        }
      }
      recipeSlugLinks {
        slug
      }
      author {
        id
        username
      }
    }
  }
`