import React from 'react'
import PropTypes from 'prop-types'

import * as S from './tags-styled.js'

const Tags = ({ tags }) => (
  <S.Container>

    {tags.map(tag => (
      <S.Tag key={tag.id}>
        {tag.Name}
      </S.Tag>
    ))}

  </S.Container>
)

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    Name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  })).isRequired,
}

export { Tags }