import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import { IngredientList } from './ingredient-list/ingredient-list-component'
import { DirectionList } from './direction-list/direction-list-component'
import { NoteList } from './note-list/note-list-component'
import { NutritionFacts } from './nutrition-facts/nutrition-facts-component'
import { social } from '../../../../constants/social'
import * as S from './recipe-card-styled'

const RecipeCard = ({
  title,
  image,
  ingredients,
  directions,
  notes,
  nutritionFacts,
  recipeSlugLinks,
}) => (
  <S.Container>

    <S.Grid>

      <S.PhotoArea>
        <Img
          fluid={image.childImageSharp.fluid}
          alt={title}
        />
      </S.PhotoArea>

      <S.IngredientsArea>
        <S.Title>Ingredients</S.Title>
        <IngredientList
          ingredients={ingredients}
          recipeSlugLinks={recipeSlugLinks}
        />
      </S.IngredientsArea>

      <S.DirectionsArea>
        <S.Title>Directions</S.Title>
        <DirectionList
          directions={directions}
        />
      </S.DirectionsArea>

      {notes && (
        <S.NotesArea>
          <S.Title>Chef's notes</S.Title>
          <NoteList
            notes={notes}
          />
        </S.NotesArea>
      )}

      <S.InstagramArea>
        <S.SalmonText>
          Please show us on Instagram if you made this recipe!
        </S.SalmonText>
        <S.GrayText>
          Tag us&nbsp;
          <S.A
            rel="noopener noreferrer"
            href={social.instagram.url}
            target="_blank"
          >
            @globalgrubbr
          </S.A>
          &nbsp;and use&nbsp;
          <S.A
            rel="noopener noreferrer"
            href={social.instagram.hashTagUrl}
            target="_blank"
          >
            #globalgrubbr
          </S.A>
        </S.GrayText>
      </S.InstagramArea>

      <S.NutritionArea>
        <S.NutritionTitle>Nutrition</S.NutritionTitle>
        <NutritionFacts
          servingSize={nutritionFacts.servingSize}
          calories={nutritionFacts.calories}
          macros={nutritionFacts.macros}
        />
      </S.NutritionArea>

    </S.Grid>

  </S.Container>
)

RecipeCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  ingredients: PropTypes.array.isRequired,
  directions: PropTypes.array.isRequired,
  notes: PropTypes.array,
  nutritionFacts: PropTypes.object.isRequired,
  recipeSlugLinks: PropTypes.array.isRequired,
}

export { RecipeCard }