import styled from 'styled-components'

import { media } from '../../../../constants/media'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media ${media.medium.query} {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
  }
`;

export const Metric = styled.div`
  display: grid;
  grid-template-rows: 50px 16px 16px;
  grid-template-columns: 100px;
  grid-template-areas:
    "icon"
    "label"
    "value";
  @media ${media.medium.query} {
    grid-template-rows: 25px;
    grid-template-columns: 50px 100px;
    grid-column-gap: 2rem;
    grid-template-areas:
      "icon label"
      "icon value";
    margin-right: 50px;
  }
`;

export const IconArea = styled.div`
  grid-area: icon;
`;

export const IconImg = styled.img`
  width: 45px;
  height: 45px;
`;

export const LabelArea = styled.div`
  grid-area: label;
  font-family: ${props => props.theme.fonts.poppins};
  font-weight: 500;
  font-size: 1.3rem;
  letter-spacing: 0.01rem;
  line-height: 1.6rem;
  color: ${props => props.theme.colors.salmon};
  @media ${media.medium.query} {
    font-size: 1.6rem;
    letter-spacing: 0.012rem;
  }
`;

export const ValueArea = styled.div`
  grid-area: value;
  font-family: ${props => props.theme.fonts.poppins};
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 1.6rem;
  color: ${props => props.theme.colors.nori};
  @media ${media.medium.query} {
    font-size: 2.3rem;
  }
`;
