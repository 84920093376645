import React from 'react'
import PropTypes from 'prop-types'

import * as S from './most-recent-video-component-styled'

const MostRecentVideoComponent = ({
  videoSrc,
  width,
  height,
  title,
}) => (
  videoSrc ? (
    <S.Container>

      <S.Iframe
        width={width}
        height={height}
        src={videoSrc}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></S.Iframe>

    </S.Container>
  ) : (
    <div></div>
  )
)

MostRecentVideoComponent.propTypes = {
  videoSrc: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

MostRecentVideoComponent.defaultProps = {
  width: '100%',
  height: '400',
}

export { MostRecentVideoComponent }