import styled from 'styled-components'

import { CountryTitle, MainTitle, BodyText } from '../../common/text-styled'
import { media } from '../../../constants/media'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 10px;
  margin-bottom: 20px;
  @media ${media.medium.query} {
    align-items: flex-start;
    text-align: left;
    margin: 0 20px;
    margin-bottom: 50px;
  }
`;

export const Country = styled(CountryTitle)`
  margin-bottom: 8px;
`;

export const Title = styled(MainTitle)`
  margin-bottom: 25px;
`;

export const Description = styled(BodyText)`
  margin-top: 50px;
  margin-bottom: 25px;
  @media ${media.medium.query} {
    margin-bottom: 35px;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  & * {
    flex: 1;
  }
  @media ${media.medium.query} {
    & * {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
`;